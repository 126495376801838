import { atom, selectorFamily } from "recoil";
import { getStudyHelp, getTestResultOverview } from "services/lrsService";
import { generateOverview } from "layout/test/testResult";
import { IOverviewQuestion } from "utils/helpers/theory/QuestionOverview";
import { ItemTitleValuePair, ResultOverview } from "services/reportingService";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { dataMultipleByContentAndVersionState, learningStructureState } from "./learningStructureState";
import { ExtendedActivity, MapReferenceKey, MapReferenceKeys } from "types/cds";
import { localStorageEffect } from "./stateHelper";
import { getStudyHelpFinal } from "services/xapiService";

export const activityProgressState = atom<ItemTitleValuePair[] | undefined>({
  key: "activityProgressState",
  default: undefined,
  effects: [localStorageEffect("activity-progress")],
});

export const testResultState = selectorFamily<
  { questions: IOverviewQuestion[]; results: ResultOverview[] } | undefined,
  string | undefined
>({
  key: "testResults",
  get:
    (assessmentId) =>
    async ({ get }) => {
      if (!assessmentId) return undefined;

      const reportOverview = await getTestResultOverview(assessmentId);
      const questions = generateOverview(reportOverview);

      return { questions, results: reportOverview };
    },
});

export const studyHelpState = selectorFamily<ExtendedActivity[] | undefined, ReferenceKey[]>({
  key: "studyHelpState",
  get:
    (keys) =>
    async ({ get }) => {
      const activitiesData = get(dataMultipleByContentAndVersionState({ keys }));

      if (!activitiesData) return undefined;

      const activitiesExtended = activitiesData.map((a: { data: string; contentId: string; versionId: string }) => {
        const activity: ExtendedActivity = JSON.parse(a.data);
        activity.contentId = a.contentId;
        activity.versionId = a.versionId;
        return activity;
      });

      if (process.env.NODE_ENV === "development") console.log("studyHelp activities", activitiesExtended);

      return activitiesExtended;
    },
});

export const studyHelpKeysState = selectorFamily<MapReferenceKeys | undefined, { userId: string; testId: string }>({
  key: "studyHelpKeysState",
  get:
    (params) =>
    async ({ get }) => {
      const referenceKey = get(learningStructureState)?.find((x) => x.parentId === 0)?.activityKey;
      if (!referenceKey) throw new Error("No reference key found");

      const response = await getStudyHelp(params.userId, referenceKey, params.testId);

      return new Map(Object.entries(response));
    },
});

export const studyHelpFinalState = selectorFamily<MapReferenceKey | undefined, { userId: string; testId: string }>({
  key: "studyHelpFinalState",
  get:
    (params) =>
    async ({ get }) => {
      const referenceKey = get(learningStructureState)?.find((x) => x.parentId === 0)?.activityKey;
      if (!referenceKey) throw new Error("No reference key found");

      const response = await getStudyHelpFinal(params.userId, referenceKey, params.testId);

      return new Map(Object.entries(response));
    },
});
