import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useToggle from "hooks/useToggle";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { Icon, IconProp, SizeProp } from "theme/icons";
import { LinearProgressIndicator } from "./LinearProgressIndicator";
import { type Variant } from "@mui/material/styles/createTypography";
import { DataTestType } from "tests/test.setup";
interface INavigationItemBase {
  children?: ReactNode;
  size?: "large" | "auto";
  href?: string;
  /**
   * Set the hrefTarget to route to an external pages
   */
  hrefTarget?: React.HTMLAttributeAnchorTarget;
  disabled?: boolean;
}

interface INavigationItem extends INavigationItemBase, DataTestType {
  text: string | ReactElement;
  subText?: string | ReactElement;
  subTextVariant?: Variant;
  /**
   * Use an array to toggle an on/off icon state
   */
  icon?: IconProp | IconProp[];
  /**
   * Default value is 'large'
   */
  iconSize?: SizeProp;
  navigationText?: string | JSX.Element;
  navigationIcon?: IconProp;
  progressValue?: number;
  color?: string;
  borderRadius?: string | number;
  width?: string | number;
  height?: string | number;
  showChildren?: boolean;
  onClick?: () => void;
}

export const NavigationItem: React.FC<INavigationItem> = (props) => {
  const theme = useTheme();
  const [showChildren, setShowChildren] = useToggle(false);
  const text =
    typeof props.text == "string" ? (
      <Typography variant="subtitle1" noWrap maxWidth="85%">
        {props.text}
      </Typography>
    ) : (
      props.text
    );

  let icon: any; // TODO:
  if (props.icon instanceof Array) {
    icon = showChildren ? props.icon[1] : props.icon[0];
  } else {
    icon = props.icon;
  }

  useEffect(() => {
    if (props.showChildren === undefined) return;
    setShowChildren(props.showChildren);
  }, [props.showChildren, setShowChildren]);

  const handleShowChildren = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, show: boolean) => {
    e.stopPropagation();
    setShowChildren(show);
  };

  return (
    <ListItem
      component={Paper}
      elevation={2}
      disablePadding
      disableGutters
      onClick={props.onClick}
      sx={{ borderRadius: props.borderRadius ?? null, height: props.height ?? "auto", width: props.width ?? "100%" }}
    >
      <Box sx={{ width: "100%" }}>
        {props.progressValue !== undefined && <LinearProgressIndicator value={props.progressValue} />}
        <NavigationItemButton
          href={props.href}
          hrefTarget={props.hrefTarget}
          size={props.size}
          dataTestIdString={props.dataTestIdString}
          disabled={props.disabled}
        >
          {icon && (
            <ListItemIcon
              sx={{ padding: "8px 16px 8px 8px", minWidth: "auto" }}
              onClick={
                icon?.iconName?.includes("chevron") ? (e) => handleShowChildren(e, !showChildren) : props.onClick
              }
            >
              <Icon
                name={icon}
                htmlColor={props.color ? props.color : theme.palette.primary.dark06}
                size={props.iconSize ? props.iconSize : "large"}
              />
            </ListItemIcon>
          )}
          <ListItemText
            primary={text}
            secondary={
              <Typography variant={props.subTextVariant ?? "body2"} component={"div"}>
                {props.subText}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            {props.navigationIcon && (
              <>
                {props.navigationText}
                <IconButton aria-label="">
                  <Icon
                    name={props.navigationIcon}
                    htmlColor={props.color ? props.color : theme.palette.primary.dark06}
                  />
                </IconButton>
              </>
            )}
          </ListItemSecondaryAction>
        </NavigationItemButton>
        <Box sx={{ display: showChildren ? "block" : "none" }}>{props.children}</Box>
      </Box>
    </ListItem>
  );
};

interface INavigationItemButton extends INavigationItemBase, DataTestType {}

const NavigationItemButton: React.FC<INavigationItemButton> = (props) => {
  const target = useMemo(() => {
    return props.hrefTarget ?? undefined;
  }, [props.hrefTarget]);

  const sx = useMemo(() => {
    return props.size === "large" ? { minHeight: "75px" } : null;
  }, [props.size]);

  const dataTestIdString = useMemo(() => {
    return `${testId.listItem.listItem}-${props.dataTestIdString}`;
  }, [props.dataTestIdString]);

  switch (true) {
    case target && !!props.href:
      return (
        <ListItemButton
          href={props.href!}
          target={target}
          sx={sx}
          data-testid={dataTestIdString}
          disabled={props.disabled}
        >
          {props.children}
        </ListItemButton>
      );
    case !target && !!props.href:
      return (
        <ListItemButton
          component={Link}
          to={props.href!}
          target={target}
          sx={sx}
          data-testid={dataTestIdString}
          disabled={props.disabled}
        >
          {props.children}
        </ListItemButton>
      );

    default:
      return (
        <ListItemButton sx={sx} data-testid={dataTestIdString} disabled={props.disabled}>
          {props.children}
        </ListItemButton>
      );
  }
};
