import { memo, PropsWithChildren } from "react";
import { AppBar, Avatar, Box, Divider, Toolbar, Theme, useTheme } from "@mui/material";
import { ButtonLink } from "utils/helpers/ButtonLink";
import { styleRoundedBottom } from "theme/styles";
import { testId } from "tests/testIdStrings";
import { useRecoilState, useRecoilValue } from "recoil";
import { userStateAvatar } from "state/userState";
import { InfoMessage } from "utils/helpers/InfoMessage";
import HeaderEducationMenu from "utils/helpers/dropdown/HeaderEducationMenu";
import { ShowConsentWarningIcon } from "./Components/ShowConsentWarningIcon";
import { iconEducator, Icon, iconUserSolid } from "theme/icons";
import { connectedEducatorsSelector } from "state/educationState";
import useMediaQuery from "@mui/material/useMediaQuery";
import useLocalStorage from "hooks/useLocalStorage";
import { HeaderDesktopMenu } from "./headerDesktopMenu";
import { desktopWidth420 } from "theme/theme";
import { useFetchFeatureFlag } from "@strmediaochitab/optima-react-components";
import { maintenanceState } from "state/recoilAtoms";

function styleTrianglePosition(isMobile: boolean) {
  return {
    position: "fixed",
    top: "60px",
    right: isMobile ? "63px" : "80px",
    zIndex: 1000000,
  };
}

function styleTriangle(color: string) {
  return {
    position: "relative",
    bottom: "-1px",
    width: 0,
    height: 0,
    borderLeft: "17px solid transparent",
    borderRight: "17px solid transparent",
    borderBottom: `17px solid ${color}`,
  };
}

export const Header = () => {
  const userAvatar = useRecoilValue(userStateAvatar);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <AppBar position="fixed" elevation={0} sx={styleRoundedBottom}>
      <Toolbar>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <HeaderEducationMenu />
          {isDesktop && <HeaderDesktopMenu />}
          <Box display="flex" justifyContent="flex-end" width="220px" alignItems="center" columnGap={1.5}>
            <ConsentWarning />
            <RegisterEducator />
            <ButtonLink to={"/more/profile"} dataTestIdString={testId.common.text.profile}>
              {userAvatar ? <Avatar src={userAvatar.src} /> : <Icon name={iconUserSolid} htmlColor="white" />}
            </ButtonLink>
          </Box>
        </Box>
        <Divider />
      </Toolbar>

      <MaintenanceMessage />
    </AppBar>
  );
};

const HeaderInfoMessage = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      position={"fixed"}
      zIndex={10001}
      top="75px"
      left={isMobile ? 0 : `calc(100vw - ${desktopWidth420} - 16px)`}
      width={isMobile ? "100%" : desktopWidth420}
      paddingLeft={2}
      paddingRight={2}
    >
      {children}
    </Box>
  );
};

const MaintenanceMessage = () => {
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useRecoilState(maintenanceState);
  const maintenanceMessage = useFetchFeatureFlag("MaintenanceMessage");

  if (!maintenanceMessage || !showMaintenanceMessage) return null;

  return (
    <HeaderInfoMessage>
      <InfoMessage
        type="info"
        message="common.app.maintenance.info"
        closeButton={() => setShowMaintenanceMessage(false)}
        showCloseButton={true}
        closeButtonColor="white"
      />
    </HeaderInfoMessage>
  );
};

const RegisterEducator = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [showLsRegisterEducatorWarning, setShowLsRegisterEducatorWarning] =
    useLocalStorage("showRegisterEducatorWarning");
  const [showLsRegisterEducatorWarningCounter, setShowLsRegisterEducatorWarningCounter] = useLocalStorage(
    "showRegisterEducatorWarningCounter"
  );

  const connectedEducators = useRecoilValue(connectedEducatorsSelector);
  const hasConnectedEducators = connectedEducators && connectedEducators.length > 0;
  const showRegisterEducator =
    showLsRegisterEducatorWarning && showLsRegisterEducatorWarningCounter <= 5 && !hasConnectedEducators;

  const closeRegisterEducatorWarning = () => {
    setShowLsRegisterEducatorWarning(false);
    const counter = Number(showLsRegisterEducatorWarningCounter) ? Number(showLsRegisterEducatorWarningCounter) + 1 : 1;
    setShowLsRegisterEducatorWarningCounter(counter);
  };

  if (hasConnectedEducators) return null;

  return (
    <>
      <ButtonLink to={"/more/educator/find"} dataTestIdString={testId.common.text.educator}>
        <Icon name={iconEducator} htmlColor="white" />
        <ShowConsentWarningIcon color={theme.palette.info.main} />
      </ButtonLink>
      {showRegisterEducator && (
        <>
          <Box sx={styleTrianglePosition(isMobile)}>
            <Box sx={styleTriangle(theme.palette.info.main)} />
          </Box>

          <HeaderInfoMessage>
            <InfoMessage
              type="info"
              message="more.educator.add.educator.info.text"
              closeButton={closeRegisterEducatorWarning}
              showCloseButton={Boolean(showLsRegisterEducatorWarning)}
              closeButtonColor="white"
            />
          </HeaderInfoMessage>
        </>
      )}
    </>
  );
});

const ConsentWarning = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [showLsConsentWarning, setShowLsConsentWarning] = useLocalStorage("showConsentWarning");

  const connectedEducators = useRecoilValue(connectedEducatorsSelector);
  const hasConnectedEducators = connectedEducators && connectedEducators.length > 0;
  const hasConsented = hasConnectedEducators ? connectedEducators[0].consented : false;
  const showConsentWarning = hasConnectedEducators && !hasConsented && showLsConsentWarning;

  if (hasConsented || !hasConnectedEducators) return null;

  return (
    <>
      <ButtonLink to={"/more/educator"} dataTestIdString={testId.common.text.educator}>
        <Icon name={iconEducator} htmlColor="white" />
        {!hasConsented && <ShowConsentWarningIcon />}
      </ButtonLink>
      {showConsentWarning && (
        <>
          <Box sx={styleTrianglePosition(isMobile)}>
            <Box sx={styleTriangle(theme.palette.warning.main)} />
          </Box>

          <HeaderInfoMessage>
            <InfoMessage
              type="warning"
              message="educator.details.share.info.warning"
              closeButton={() => setShowLsConsentWarning(false)}
              showCloseButton={Boolean(showLsConsentWarning)}
            />
          </HeaderInfoMessage>
        </>
      )}
    </>
  );
});
