import React from "react";
import { Box, ListItemButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { useAppIntl } from "services/useAppIntl";
import { styleTabContent, testTypeName } from "./allTestResults";
import { useNavigate } from "react-router-dom";

type Props = {
  testResults: ItemTestResultItem[];
};

export const StudyTest: React.FC<Props> = ({ testResults }) => {
  const intl = useAppIntl();
  const navigate = useNavigate();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  const openTest = (id: string) => {
    navigate("" + id);
  };

  return (
    <>
      {testResults
        .map((row, i) => {
          const { duration, finished, max, title, value, statementRef } = row;
          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });

          return (
            <ListItemButton key={i} sx={styleTabContent} onClick={() => openTest(statementRef)}>
              <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                <Typography width={65}>
                  {value}/{max}
                </Typography>
                <Typography variant="caption" display={"flex"} textAlign={"left"} pr={2}>
                  {testTypeName(title)}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"} textAlign={"right"}>
                <Typography variant="body2">{testDuration}</Typography>
                <Typography variant="caption">{getDateWithTime(finished)}</Typography>
              </Box>
            </ListItemButton>
          );
        })
        .reverse()}
    </>
  );
};

// interface IDialogQuestion {
//   open: boolean;
//   close: () => void;
//   confirm?: () => void;
//   children?: React.ReactNode;
// }

// const DialogQuestion = ({ open, children, close, confirm }: IDialogQuestion) => {
//   const handleClose = () => {
//     close();
//   };

//   const handleConfirm = () => {
//     close();
//     if (confirm) confirm();
//   };

//   return (
//     <DialogMessage
//       title="question.close-result"
//       open={open}
//       close={handleClose}
//       primaryButtonText="question.close-result"
//       primaryButtonAction={() => handleConfirm()}
//       // dataTestIdString={testId.common.close}
//     >
//       {children}
//     </DialogMessage>
//   );
// };
