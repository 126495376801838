import { useAppIntl } from "services/useAppIntl";
import { iconAdd, iconAward } from "theme/icons";
import { IMenuItem } from "utils/helpers/dropdown/MenuItems";
import { AssessmentType } from "./useAssessment";
import { appConfig } from "appConfig";
import { useRecoilValue } from "recoil";
import { educationState } from "state/educationState";
import { useEffect, useState } from "react";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";

interface IMenuItemQuestionType extends IMenuItem {
  id: AssessmentType;
}
export type TestSettings = {
  questions: number;
  duration?: number;
  questionType: AssessmentType;
  subjects: Set<string>;
  ratio?: number;
  skip?: number;
};

// Type for final test configuration (coming from appConfig)
type FinalTestConfig = {
  keys: ReferenceKey[];
  config: TestSettings;
};

const defaultFinalTest: TestSettings = {
  questions: 70,
  duration: 50,
  questionType: "final",
  subjects: new Set(),
  ratio: 52,
  skip: 5,
};

const envFinalTestSettings = JSON.parse(appConfig.finalTestSettings) as FinalTestConfig[] | undefined;

export const useTestSettings = () => {
  const intl = useAppIntl();
  const education = useRecoilValue(educationState);
  const [finalTest, setFinalTest] = useState<TestSettings>(defaultFinalTest);

  const defaultTest: TestSettings = {
    questions: 10,
    duration: 10,
    questionType: "study",
    subjects: new Set(),
  };

  const quickTest: TestSettings = {
    questions: 10,
    questionType: "study",
    subjects: new Set(),
  };

  const quickTest2: TestSettings = {
    questions: 70,
    questionType: "study",
    subjects: new Set(),
    duration: 50,
  };

  const roadsignsTest: TestSettings = {
    questions: 20,
    questionType: "roadsigns",
    subjects: new Set(),
  };

  const numOfQuestions = () => {
    const alternatives: IMenuItem[] = [];
    alternatives.push({ id: "10", title: "10 st" });
    alternatives.push({ id: "20", title: "20 st" });
    alternatives.push({ id: "40", title: "40 st" });
    alternatives.push({ id: "70", title: "70 st" });
    return alternatives;
  };

  const duration = () => {
    const alternatives: IMenuItem[] = [];
    alternatives.push({ id: "0", title: intl.formatMessage({ id: "test.create.custom.dropdown.duration" }) });
    alternatives.push({ id: "10", title: "10 min" });
    alternatives.push({ id: "20", title: "20 min" });
    alternatives.push({ id: "30", title: "30 min" });
    alternatives.push({ id: "40", title: "40 min" });
    alternatives.push({ id: "50", title: "50 min" });
    alternatives.push({ id: "60", title: "60 min" });
    alternatives.push({ id: "70", title: "70 min" });
    alternatives.push({ id: "80", title: "80 min" });
    return alternatives;
  };

  const questionTypes = () => {
    const alternatives: IMenuItemQuestionType[] = [];
    alternatives.push({ id: "study", icon: iconAdd, title: intl.formatMessage({ id: "common.title.study" }) });
    alternatives.push({ id: "roadsigns", icon: iconAward, title: intl.formatMessage({ id: "common.roadsigns" }) });
    return alternatives;
  };

  // Find the finalTest settings for the current education
  useEffect(() => {
    if (!education?.contentId || !education?.versionId) return;

    const educationSettings = envFinalTestSettings?.find((config) =>
      config.keys.find((key) => key.contentId === education.contentId && key.versionId === education.versionId)
    );

    if (educationSettings) {
      if (process.env.NODE_ENV === "development")
        console.log(
          "finalTest settings found for education with",
          `contentId: ${education.contentId}, versionId: ${education.versionId}`,
          educationSettings
        );

      setFinalTest(educationSettings.config);
    } else {
      if (process.env.NODE_ENV === "development")
        console.log("finalTest settings not found, using default", defaultFinalTest);

      setFinalTest(defaultFinalTest);
    }
  }, [education?.contentId, education?.versionId]);

  return { defaultTest, finalTest, roadsignsTest, quickTest, quickTest2, numOfQuestions, duration, questionTypes };
};
