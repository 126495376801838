import { useState } from "react";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import {
  iconArrowCircleRight,
  iconClockFilled,
  iconAward,
  iconLock,
  iconLockOpen,
  iconTriangleSolid,
  iconTestActive,
  iconUserPen,
  Icon,
  iconCircleInfoSolid,
  iconShuffle,
} from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FinalTestDialog } from "layout/test/final/finalTestDialog";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useNavigate } from "react-router-dom";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";
import { desktopWidth420 } from "theme/theme";
import { InfoDialog } from "./infoDialog";
import { InfoFinalTestDialog } from "./infoFinalTestDialog";
import { testId } from "tests/testIdStrings";
import { useFinalTestStatus } from "hooks/useFinalTestStatus";
import SoundSupport from "layout/Components/SoundSupport";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";
import { HowToUseTestVideo } from "layout/more/getStarted";
import NavigationItemStudyHelp from "layout/Components/NavigationItemStudyHelp";

export const Test = () => {
  const intl = useAppIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const routes = useRouteConfiguration();
  const { finalTestEnabled, finalTestUnlocked } = useFinalTestStatus();
  const [openFinalTestDialog, setOpenFinalTestDialog] = useState(false);
  const [openTestInfoDialog, setOpenTestInfoDialog] = useState(false);
  const [openFinalTestInfoDialog, setOpenFinalTestInfoDialog] = useState(false);

  const handleCreateFinalTest = () => {
    if (!enableFinalTest) return setOpenFinalTestDialog(true);

    navigate(routes.test.routes!.final.path);
  };

  const enableFinalTest = finalTestEnabled || finalTestUnlocked;

  return (
    <>
      <BackgroundShape top={-150} left={-270}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="650" height="300" color={svgColor.pink} />
      </BackgroundShape>
      <Stack spacing={4} sx={{ maxWidth: desktopWidth420, marginLeft: "auto", marginRight: "auto" }}>
        <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]} mt={-1}>
          <FormattedTypography id="common.title.test" variant="h1" />
          <IconButton onClick={() => setOpenTestInfoDialog(true)}>
            <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.info.main} />
          </IconButton>
        </Box>
        <HowToUseTestVideo />
        <Stack spacing={2}>
          <FormattedTypography id="common.title.study" variant="h2" />
          <NavigationItem
            size="auto"
            color={theme.palette.primary.dark06}
            text={intl.formatMessage({ id: "test.type.study.quick" })}
            subText={
              <FormattedTypography id="test.type.study.quick.subtext" variant="breadcrumbs" values={{ value: 10 }} />
            }
            icon={iconClockFilled}
            iconSize="medium"
            navigationIcon={iconArrowCircleRight}
            href={"quick/study"}
            dataTestIdString={testId.listItem.test.type.quick}
          />
          <NavigationItem
            size="auto"
            color={theme.palette.primary.dark06}
            text={intl.formatMessage({ id: "common.title.test.quick2" })}
            subText={
              <FormattedTypography id="test.type.study.quick2.subtext" variant="breadcrumbs" values={{ value: 10 }} />
            }
            icon={iconShuffle}
            iconSize="medium"
            navigationIcon={iconArrowCircleRight}
            href={"quick/study2"}
            dataTestIdString={testId.listItem.test.type.quick}
          />
          <NavigationItem
            size="auto"
            color={theme.palette.primary.dark06}
            text={intl.formatMessage({ id: "test.type.roadsign.quick" })}
            subText={
              <FormattedTypography id="test.type.study.quick.subtext" variant="breadcrumbs" values={{ value: 20 }} />
            }
            icon={iconTriangleSolid}
            iconSize="medium"
            navigationIcon={iconArrowCircleRight}
            href={"quick/roadsigns"}
            dataTestIdString={testId.listItem.test.type.roadsign}
          />
          <NavigationItem
            size="auto"
            color={theme.palette.primary.dark06}
            text={intl.formatMessage({ id: "test.create.custom" })}
            subText={<FormattedTypography id="test.create.custom.subtext" variant="breadcrumbs" />}
            icon={iconUserPen}
            iconSize="medium"
            navigationIcon={iconArrowCircleRight}
            href={routes.test.routes?.custom.path}
            dataTestIdString={testId.listItem.test.type.custom}
          />
        </Stack>

        <Stack spacing={1}>
          <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]} mt={-1}>
            <FormattedTypography id="common.title.test.final" variant="h2" />
            <IconButton onClick={() => setOpenFinalTestInfoDialog(true)}>
              <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.info.main} />
            </IconButton>
          </Box>

          <NavigationItem
            disabled={!enableFinalTest}
            size="auto"
            color={theme.palette.primary.dark}
            text={intl.formatMessage({ id: "test.start.final" })}
            subText={<FormattedTypography id="test.start.final.subtext" variant="breadcrumbs" />}
            icon={iconAward}
            iconSize="medium"
            navigationIcon={enableFinalTest ? iconLockOpen : iconLock}
            onClick={handleCreateFinalTest}
            dataTestIdString={testId.listItem.test.type.final}
          />
        </Stack>

        <Stack spacing={2}>
          <FormattedTypography id="common.result" variant="h2" />
          <NavigationItemStudyHelp />
          <NavigationItem
            size="auto"
            color={theme.palette.info.dark}
            text={intl.formatMessage({ id: "test.history.all" })}
            icon={iconTestActive}
            iconSize="medium"
            navigationIcon={iconArrowCircleRight}
            href={routes.progress.path + "/" + routes.progress.routes?.results.path}
            dataTestIdString={testId.listItem.test.common.historyAll}
          />
        </Stack>

        <SoundSupport />
      </Stack>

      <FinalTestDialog open={openFinalTestDialog} close={() => setOpenFinalTestDialog(false)} />
      <InfoDialog open={openTestInfoDialog} close={() => setOpenTestInfoDialog(false)} />
      <InfoFinalTestDialog open={openFinalTestInfoDialog} close={() => setOpenFinalTestInfoDialog(false)} />

      <BackgroundShape bottom={-300} position="fixed" center stickToBottom>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="1000" height="400" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};
