import { FC } from "react";
import { AppBar, Box, Toolbar, Theme } from "@mui/material";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { styleRoundedBottom } from "theme/styles";
import { useNavigate } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { useRouteConfiguration, RouteKey, SubRouteKey } from "hooks/useRouteConfiguration";
import useRoutePath from "hooks/useRoutePath";
import { IHeaderProps } from "layout/more/header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderDesktopMenu } from "../headerDesktopMenu";
import { getParentPath } from "routes";

export const Header: FC<IHeaderProps> = ({ children, callback }) => {
  const path = useRoutePath();
  const routes = useRouteConfiguration();
  const navigate = useNavigate();
  const intl = useAppIntl();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  // Extract route names
  const paths = path.replace("/", "").split("/");
  const main = routes[paths[0] as RouteKey];
  const topTitle = main.name;
  let title = main.routes ? main.routes[paths[1] as SubRouteKey].name : "";
  const url = window.location.href;

  if (url.includes("roadsigns")) title = intl.formatMessage({ id: "header.title.roadsignstest" });

  const navigateBack = () => {
    if (callback) return callback();

    let parent = getParentPath(path);
    // If parent is a result page (study help), navigate to its parent
    if (parent.endsWith("/result")) parent = getParentPath(parent);

    // If parent is a final result page, navigate to its parent and add hash (for tab nav)
    if (parent.includes("/results/final")) parent = getParentPath(parent) + "#final";

    return navigate(parent);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={styleRoundedBottom}>
      <Toolbar>
        <HeaderNavigation iconSize={"large"} topTitle={topTitle} title={title} onNavigate={navigateBack} />
        {isDesktop && <HeaderDesktopMenu />}
        <Box width="220px" />
      </Toolbar>
      {children}
    </AppBar>
  );
};
