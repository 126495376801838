import { useState } from "react";
import { Stack, Box, Card, useTheme, IconButton, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Icon, iconArrowCircleRight, iconCircleInfoSolid, iconChartColumnSolid, iconTestActive } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { IntlMessageKeys, useAppIntl } from "services/useAppIntl";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { CircularProgressIndicator } from "utils/helpers/CircularProgressIndicator";
import { testId } from "tests/testIdStrings";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useProgress } from "hooks/useProgress";
import { desktopWidth420 } from "theme/theme";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";
// import NavigationItemStudyHelp from "layout/Components/NavigationItemStudyHelp";

const imgNoProgress = process.env.PUBLIC_URL + "/img/illustrations/test-failure.svg";
const styleLink = (theme: Theme) => {
  return { color: theme.palette.primary.dark06, textDecoration: "none", fontStyle: "italic" };
};
const styleCaption = { display: "inline-block", textAlign: "center" };

export const Progress = () => {
  const theme = useTheme();
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const { knowledgeLevelUser } = useProgress();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState<IntlMessageKeys>("progress.knowledgelevel");
  const [dialogText, setDialogText] = useState<IntlMessageKeys>("progress.dialog.knowledgelevel");

  const progress = knowledgeLevelUser ?? 0;

  if (knowledgeLevelUser === undefined) return null;

  function openDialog(title: IntlMessageKeys, text: IntlMessageKeys) {
    setDialogTitle(title);
    setDialogText(text);
    setShowDialog(true);
  }

  return (
    <>
      <BackgroundShape top={-150} left={-240}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="650" height="300" color={svgColor.pink} />
      </BackgroundShape>
      <Stack spacing={4} sx={{ maxWidth: desktopWidth420, marginLeft: "auto", marginRight: "auto" }}>
        <FormattedTypography id="common.title.progress" variant="h1" mb={6} />
        <Stack>
          <Box display="flex" justifyContent="space-between" alignItems={"center"}>
            <FormattedTypography id="progress.knowledgelevel" variant="h2" />
            <IconButton onClick={() => openDialog("progress.knowledgelevel", "progress.dialog.knowledgelevel")}>
              <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.primary.dark06} />
            </IconButton>
          </Box>
          {progress !== 0 ? <ProgressCard progress={progress} /> : <NoProgressCard progress={progress} />}
        </Stack>

        <Stack spacing={1.5}>
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "progress.knowledgelevel.per.topic.title" })}
            icon={iconChartColumnSolid}
            color={theme.palette.primary.dark06}
            iconSize="medium"
            dataTestIdString={testId.listItem.theory.continue}
            navigationIcon={iconArrowCircleRight}
            href={"/progress/" + routes.progress.routes?.progresspertopic.path}
          />
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "progress.knowledgelevel.history" })}
            icon={iconTestActive}
            color={theme.palette.primary.dark06}
            iconSize="medium"
            dataTestIdString={testId.listItem.theory.continue}
            navigationIcon={iconArrowCircleRight}
            href={"/progress/" + routes.progress.routes?.results.path}
          />
        </Stack>

        {/* Ej helt bestämt om denna ska vara med eller inte så låter den vara kommenterad för nu */}
        {/* <Stack>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={1}>
            <FormattedTypography id="progress.studyhelp" variant="h2" />
            <IconButton
              onClick={() => openDialog("progress.studyhelp", "progress.dialog.studyhelp")}
              sx={{ padding: 1 }}
            >
              <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.primary.dark06} />
            </IconButton>
          </Box>
          <NavigationItemStudyHelp />
        </Stack> */}
      </Stack>

      <DialogMessage
        title={dialogTitle}
        open={showDialog}
        close={() => setShowDialog(false)}
        primaryButtonText={"common.close"}
        dataTestIdString={`${testId.listItem.profile.contactInfoBox}`}
        noCancelButton
      >
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage id={dialogText} />
        </Typography>
      </DialogMessage>
      <BackgroundShape bottom={-300} position="fixed" center stickToBottom>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="1000" height="400" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};

const ProgressCard = ({ progress }: { progress: number }) => {
  return (
    <Card sx={{ padding: 2, marginTop: 1 }}>
      <CircularProgressIndicator value={progress} />
      <Typography variant="caption" sx={{ width: "100%", display: "inline-block", textAlign: "center" }}>
        <FormattedMessage id="progress.knowledgelevel.total" />
      </Typography>
    </Card>
  );
};

const NoProgressCard = ({ progress }: { progress: number }) => {
  const intl = useAppIntl();
  const theme = useTheme();

  const theoryLink = (
    <Link style={styleLink(theme)} to="/theory">
      <FormattedMessage id="progress.answertheory.link" />
    </Link>
  );

  const testLink = (
    <Link style={styleLink(theme)} to="/test">
      <FormattedMessage id="common.title.test" />
    </Link>
  );

  return (
    <Card sx={{ display: "flex", justifyContent: "center", flexDirection: "column", padding: 2, marginTop: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={imgNoProgress} height={"150px"} alt="No progress" />
      </Box>
      <Typography variant="caption" sx={styleCaption}>
        {intl.formatMessage({ id: "progress.zeroprogress.line1" }, { values: { percent: progress } })}
      </Typography>
      <Typography variant="caption" sx={styleCaption}>
        {intl.formatMessage({ id: "progress.zeroprogress.line2" }, { values: { theoryLink } })}
      </Typography>
      <Typography variant="caption" sx={styleCaption}>
        {intl.formatMessage({ id: "progress.zeroprogress.line3" }, { values: { testLink } })}
      </Typography>
    </Card>
  );
};
