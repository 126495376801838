import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { iconArrowCircleRight, iconChevronDown, iconChevronUp } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { TreeNode } from "types/cds";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { theoryChapterState, theoryAreaState, theoryStateChildrenByParentId } from "state/theoryState";
import { useNavigate, useLocation } from "react-router-dom";
import { TheorySections } from "./sections";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import useChapterActivities from "hooks/useChapterActivities";
import SvgImage, { svgColor, ellipse3, ellipse4 } from "utils/helpers/SvgImage";
import { activityProgressState } from "state/progresState";

interface TheoryChaptersProps {}

export const TheoryChapters: React.FC<TheoryChaptersProps> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const area = useRecoilValue(theoryAreaState);
  const chapters = useRecoilValue(theoryStateChildrenByParentId(area ? area.id : -1));
  const setChapterState = useSetRecoilState(theoryChapterState);
  const { setChapterActivities } = useChapterActivities();
  const progress = useRecoilValue(activityProgressState);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSelectedItem = (chapter: TreeNode) => {
    setChapterState(chapter);
    // För learning state
    setChapterActivities(chapter);
    navigate(`${chapter.id}/read`);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <BackgroundShape top={-400}>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={10}
          width="1230"
          height="660"
          color={svgColor.pink}
        />
      </BackgroundShape>
      <BackgroundShape top={550} left={-360}>
        <SvgImage
          d={ellipse3.d}
          viewBox={ellipse3.viewBox}
          rotation={100}
          width="530"
          height="360"
          color={svgColor.turquoise}
        />
      </BackgroundShape>

      <BackgroundShape top={500} right={-350}>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={275}
          width="530"
          height="360"
          color={svgColor.pink}
        />
      </BackgroundShape>

      <Stack spacing={3} maxWidth={"440px"} marginLeft="auto" marginRight="auto">
        {chapters.map((chapter: TreeNode, index: number) => (
          <NavigationItem
            size="large"
            key={chapter.id}
            text={
              <Typography variant="h3" noWrap maxWidth="85%">
                {chapter.title}
              </Typography>
            }
            icon={[iconChevronDown, iconChevronUp]}
            navigationIcon={iconArrowCircleRight}
            onClick={() => handleSelectedItem(chapter)}
            progressValue={parseInt(progress?.find((p) => p.title === chapter.activityKey?.versionId)?.value || "0")}
            dataTestIdString={`${testId.listItem.theory.chapters}-${index + 1}`}
          >
            <TheorySections chapterId={chapter.id} />
          </NavigationItem>
        ))}
      </Stack>
    </Box>
  );
};
