import { Backdrop, Box, IconButton } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { Icon, iconClose } from "theme/icons";

type ZoomImageProps = PropsWithChildren & {
  src?: string;
  alt?: string;
};

const ZoomImageOverlay = ({ src, alt, children }: ZoomImageProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const body = document.getElementsByTagName("body")[0];

  const handleOpen = () => {
    body.style.overflow = "hidden";
    setShowOverlay(true);
  };

  const handleClose = () => {
    setShowOverlay(false);
    body.style.overflow = "";
  };

  return (
    <>
      <Box onClick={handleOpen}>{children}</Box>
      {showOverlay && (
        <Backdrop
          sx={{
            backgroundColor: "rgba(0,0,0,0.85)",
            zIndex: 1500,
            mt: "0 !important",
            ".react-transform-wrapper": { width: "100%", height: "100%" }, // Fix for container overflow (needs mobile testing)
          }}
          open
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.75)",
              zIndex: 5,
              top: 16,
              right: 16,
              "&:hover": { backgroundColor: "rgba(0,0,0,0.5)" },
            }}
          >
            <Icon name={iconClose} htmlColor="white" />
          </IconButton>
          <TransformWrapper>
            <ZoomImage>{children}</ZoomImage>
          </TransformWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default ZoomImageOverlay;

const ZoomImage = ({ children }: ZoomImageProps) => {
  // const { instance, zoomIn, zoomOut } = useControls();

  return (
    <TransformComponent>
      {/* <img src={src} alt={alt} /> */}
      {children}
    </TransformComponent>
  );
};

const viewport = document.querySelector("meta[name=viewport]");
const initialViewport = viewport?.getAttribute("content");

// The toggle view port updates the
// function toggleViewportScaling(){

//   const content = allowScale?
//       'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no':'width=device-width, initial-scale=1.0, user-scalable=yes';
//   // this is where the magic happens by changing the vewport meta tag
//   viewport.setAttribute('content', content);
//   allowScale = !allowScale;
//   showViewportState();
// }
