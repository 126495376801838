import { useTheme } from "@mui/material";
import { useTestResult } from "hooks/useTestResult";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemConfigurationTypeKnowledge } from "services/reportingService";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { iconGlasses, iconArrowCircleRight } from "theme/icons";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { NavigationItem } from "utils/helpers/NavigationItem";

const NavigationItemStudyHelp = ({ path }: { path?: string }) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const navigate = useNavigate();
  const { testResults } = useTestResult();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    if (!testResults || testResults.length === 0) return setShowDialog(true);

    // Filter out only study tests
    const studyTests = testResults.filter((test) => test.configuration.name === ItemConfigurationTypeKnowledge.StudyA);
    if (studyTests.length === 0) return setShowDialog(true);

    // Get the latest test
    const latestTest = testResults.reduce((prev, current) =>
      new Date(prev.finished) > new Date(current.finished) ? prev : current
    );

    navigate(path ?? "result/" + latestTest.statementRef);
  };

  return (
    <>
      <NavigationItem
        size="large"
        color={theme.palette.info.dark}
        iconSize="medium"
        text={intl.formatMessage({ id: "progress.studyhelp" })}
        subText={intl.formatMessage({ id: "progress.studyhelp.navigate.subtext" })}
        subTextVariant={"caption"}
        icon={iconGlasses}
        dataTestIdString={testId.listItem.theory.continue}
        navigationIcon={iconArrowCircleRight}
        onClick={handleClick}
      />

      {showDialog && <Dialog open={true} close={() => setShowDialog(false)} />}
    </>
  );
};

export default NavigationItemStudyHelp;

type Props = {
  open: boolean;
  close: () => void;
};

export const Dialog = (props: Props) => {
  const { open, close } = props;

  const handleClose = () => {
    close();
  };

  return (
    <DialogMessage
      title="progress.studyhelp"
      open={open}
      close={handleClose}
      primaryButtonText="common.close"
      noCancelButton
      dataTestIdString={testId.common.close}
    >
      <FormattedTypography
        id={"progress.studyhelp.no-results"}
        values={{
          br: (
            <>
              <br />
              <br />
            </>
          ),
        }}
      />
    </DialogMessage>
  );
};
