import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useTestSettings } from "hooks/useTestSettings";
import { useTestResult } from "hooks/useTestResult";
import { useDateService } from "hooks/useDateService";
import { useTopNavigation } from "layout";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { studyHelpFinalState } from "state/progresState";
import { useAccount } from "@azure/msal-react";
import { loadingState } from "state/recoilAtoms";

const imgSuccess = process.env.PUBLIC_URL + "/img/illustrations/test-success.svg";
const imgFailure = process.env.PUBLIC_URL + "/img/illustrations/test-failure.svg";

export const TestResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { testId } = location?.state;
  const { setTopNavigate } = useTopNavigation();

  useEffect(() => {
    setTopNavigate({ function: handleNavigateBack });

    return () => {
      setTopNavigate({ function: undefined });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateBack = () => navigate("/test", { replace: true });

  return (
    <>
      <Result testId={testId} />
      <Button variant="contained" fullWidth onClick={handleNavigateBack}>
        <FormattedMessage id="common.close" />
      </Button>
    </>
  );
};

interface IResult {
  testId: string;
}
export const Result = ({ testId }: IResult) => {
  const setIsLoading = useSetRecoilState(loadingState);
  const { finalTest } = useTestSettings();
  const { getDuration } = useDateService();
  const { testResult } = useTestResult({ testId, retry: true });

  // Hide loader when testResult is resolved
  useEffect(() => {
    if (!testResult) return;
    setIsLoading(false);
  }, [setIsLoading, testResult]);

  if (!testResult) return null;

  const { value, max, duration } = testResult;
  const testPassed = parseInt(value) >= finalTest.ratio!;

  return (
    <>
      <FormattedTypography variant="h1" marginBottom={2} id="test.start.final" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {<img src={testPassed ? imgSuccess : imgFailure} height={"240px"} alt="Testresult" />}
        <Typography marginTop={3} variant="h2">
          <FormattedMessage id={testPassed ? "common.title.passed" : "common.title.failed"} />
        </Typography>
        <Typography marginTop={2} variant="h2">
          {value}/{max}
        </Typography>
        <Typography marginTop={2} variant="body2" fontSize={14}>
          {duration && getDuration(duration)}
        </Typography>
      </Box>

      <StudyHelp testId={testId} />
    </>
  );
};

const StudyHelp = ({ testId }: { testId: string }) => {
  const account = useAccount();
  const studyHelp = useRecoilValue(studyHelpFinalState({ userId: account!.localAccountId, testId: testId }));

  if (!studyHelp) return <Box height={"15vh"} />;

  return (
    <>
      <FormattedTypography fontWeight={600} fontSize={"20px"} marginTop={5} id="progress.more.exercise" />
      <Box marginTop={2} marginBottom={4} paddingBottom={1}>
        {Array.from(studyHelp.entries()).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              padding: 2,
              marginLeft: "-16px",
              width: "100vw",
            }}
          >
            <Typography fontSize={"16px"}>{key}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};
